"use client";

// Error components must be Client Components
import { Button, Result } from "antd";
import { useEffect } from "react";
export default function ErrorPage({
  error,
  reset
}: Readonly<{
  error: {
    digest?: string;
  } & Error;
  reset: () => void;
}>) {
  useEffect(() => {
    console.error(error);
  }, [error]);
  const handleReloadPage = () => {
    window.location.reload();
  };
  return <Result extra={<Button htmlType="button" onClick={() => handleReloadPage()} type="primary">
          Reload Page
        </Button>} status="500" subTitle="Sorry, something went wrong., please try again to reload a page." title="500" data-sentry-element="Result" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx" />;
}